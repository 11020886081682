.form {
  padding: 100px 150px 150px;
  height: 510px;
  max-width: 458px;
  margin: 0 auto 240px;
  display: flex;
  flex-direction: column;
  background-image: url('../../images/form-bg.png');
  background-size: 130%;
  background-position: center;
  background-repeat: no-repeat;

  &__email-wrap {
    margin-bottom: 40px;
  }

  &__select-wrap {
    margin-bottom: 48px;
  }

  &__radio {
    align-items: center;
  }

  &__radio-title {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    margin-left: 20px;
  }

  &__content {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 44px;
  }

  &__select {
    width: 100%;
  }

  &__error {
    font-size: 18px;
    color: red;
    margin-top: 5px;
  }

  &__button {
    width: 100%;
    cursor: pointer;
    background: #09A0EE;
    border-radius: 40px;
    font-size: 24px;
    color: #fff;
    border: none;
    padding: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    transition: 0.4s;

    &:disabled {
      pointer-events: none;
      opacity: 0.7;
    }

    &:hover {
      background-color: #149ff1;
    }
    
    &:active {
      background-color: #067fc5;
    }
  }

  @media all and (max-width: 700px) {
    padding: 50px 10px;
    background: none;
  }

  @media all and (max-width: 576px) {
    padding: 0 20px 90px;

    &__radio-row {
      margin-left: 20px;
    }

    &__email-wrap,
    &__select-wrap {
      margin-bottom: 30px;
    }

    &__button {
      padding: 16px;
    }

    &__radio {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin: 24px auto 16px;
      font-size: 24px;
      max-width: 287px;
    }

    &__radio-title {
      font-size: 18px;
    }
  }
}