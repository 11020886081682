.logo {
  width: 141px;
  height: 123px;

  &__title {
    letter-spacing: 2px;
    font-size: 45px;
    font-family: 'Open Sans', sans-serif;
    background: -webkit-linear-gradient(#09A0EE, #00D0BD);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media all and (max-width: 576px) {
    width: 90px;
    height: 79px;

    &__title {
      font-size: 24px;
    }
  }
}