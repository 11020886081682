body {
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff
}

.text-semibold {
  font-weight: 500;
}

.text-normal {
  font-weight: 300;
}

.root {
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  max-width: 1840px;
  margin: auto;
  overflow: hidden;
}

.main-page {
  margin: auto;

  &__content {
    background-image: url("images/angel-right.png"), url("images/content-bg.png");
    background-repeat: no-repeat;
    background-position: calc(100% + 185px) calc(100% - 70px), center, center;
    background-size: 700px, cover;
    padding-top: 160px;

    @media all and (max-width: 576px) {
      padding-top: 140px;
      background-image: url("images/angel-right-mob.png"), url("images/content-bg-mob.png");
      background-position: 100% calc(100% - 185px), center 10%;
      background-size: auto, cover;
    }
  }
}

.thank-page {
  background-color: #EBF5F7;
  background-image: url("images/angel-left.png"), url("images/angel-right.png"), url("images/cloud-left-top.png"), url("images/cloud-right-bottom.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: left bottom, calc(100% + 270px) 20px, left top, right bottom;
  background-size: auto, 700px, auto, auto;
  min-height: 90vh;
  padding: 50px 20px 70px;

  &__title {
    margin-bottom: 116px;
    font-size: 48px;
    font-weight: 500;
  }

  @media all and (max-width: 576px) {
    height: 855px;
    padding-top: 80px;
    background-image: url("images/cloud-top-left-mob.png"), url("images/cloud-top-right-mob.png"), url("images/angel-left-thank-mob.png"), url("images/angel-right-thank-mob.png");
    background-position: top left, top right, 0 calc(100% - 20px), 100% calc(100% - 150px);
    background-size: auto, auto;
  }
}

.footer {
  justify-content: center;
  padding-left: 48px;
  padding-bottom: 72px;

  @media all and (max-width: 576px) {
    padding-left: 0;
    padding-bottom: 0;
  }
}
