.social-button {
  background-image: url("../../images/social-bg.png");
  background-size: contain;
  width: 95px;
  height: 95px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px;
  position: relative;

  @media all and (max-width: 576px) {
    width: 90px;
    height: 90px;
  }
}

.social-icon {
  background-image: url("../../images/social-icons.svg");
  background-position: 0 0;
  background-size: 137px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 25px;
  height: 25px;

  &__instagram {
    background-position-x: -68px;
  }

  &__facebook {
    background-position-x: -38px;
    width: 12px;
  }

  &__youtube {
    background-position-x: -88px;
    background-size: 113px;
    height: 19px;
  }
}

.social-button__link {
  text-decoration: none;
}