.rb-label {
  .MuiTypography-root {
    font-size: 24px;
    color: #fff;

    @media all and (max-width: 576px) {
      font-size: 18px;
    }
  }
}

.radio__icon {
  height: 19px;
  width: 19px;
  background-color: #fff;
  border-radius: 40px;
  position: relative;

  &-checked:after {
    content: '';
    width: 7px;
    height: 7px;
    background-color: #09A0EE;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    border-radius: 20px;
  }

  @media all and (max-width: 576px) {
    width: 49px;
    height: 49px;

    &-checked:after {
      width: 18px;
      height: 18px;
    }
  }
}