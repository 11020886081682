.content {
  max-width: 500px;
  margin: auto;

  &__title {
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
  }

  &__description {
    font-size: 20px;
    margin-bottom: 0;
  }

  @media all and (max-width: 576px) {
    &__title {
      font-size: 24px;
      line-height: 24px;
      max-width: 255px;
      margin: 86px auto 24px;
    }

    &__description {
      font-size: 18px;
      max-width: 287px;
      margin: auto;
    }
  }
}