.select {
  height: 60px;
  color: #fff;
  width: 100%;

  &__placeholder .MuiSelect-nativeInput {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    padding-bottom: 15px;
    padding-left: 36px;
    opacity: 1;

    &::-webkit-input-placeholder {
      color: #fff;
    }

    &:focus::-webkit-input-placeholder {
      color: #fff;
    }
  }

  &__input {
    margin-top: 5px;
    padding: 0 0 2px 0;
    font-size: 16px;
    color: #333;
    border: none;
    border-bottom: 1px solid #808080;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #808080;
    }

    &::-webkit-input-placeholder {
      color: #333;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }

  &__list {
    &.MuiList-padding {
      padding: 16px;
    }
  }

  &__paper.MuiPaper-rounded {
    border-radius: 20px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    border: 1px solid #808080;
    border-radius: 4px;

    &-checked {
      width: 18px;
      height: 18px;
      border: none;
      background: url("../../images/checkbox-checked.svg");
      background-size: cover;
    }
  }

  &__menu-item {
    &.MuiMenuItem-root {
      margin: 0;
      padding: 0;

      &:active {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }

      &.Mui-selected {
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .MuiListItemText-root {
      margin: 0;
    }

    .MuiListItemIcon-root {
      margin-right: 0;
      min-width: auto;
    }
  }

  &__wrapper {
    width: 100%;

    .MuiSelect-icon {
      color: #fff;
      font-size: 28px;
      transform: rotate(90deg);
      margin-right: 20px;
    }

    .MuiSelect-iconOpen {
      transform: rotate(-90deg);
    }

    .MuiMenu-paper {
      border-radius: 20px;
    }

    &.MuiFormControl-root {
    }

    .MuiSelect-select {
      border: 3px solid #fff;
      border-radius: 40px;
      height: 54px;
      padding: 0;
      line-height: 54px;
      font-size: 24px;
      color: #fff;

      &.MuiSelect-select {
        padding-right: 60px;
        padding-left: 34px;
      }

      &:focus {
        border-radius: 40px;
        background: none;
      }
    }

    .MuiInputBase-root:before {
      display: none;
    }

    .MuiInput-underline:after {
      display: none;
    }
  }

  @media all and (max-width: 576px) {
    &__input {
      margin-top: 6px;
      font-size: 20px;
      padding-bottom: 8px;
    }

    &__menu-item {
      &.MuiMenuItem-root span {
        font-size: 20px;
      }
    }

    &__list {
      &.MuiList-padding {
        padding: 6px;
      }
    }

    &__placeholder .MuiSelect-nativeInput {
      font-size: 18px;
      padding-bottom: 13px;
    }

    &__icon {
      width: 38px;
      height: 38px;

      &-checked {
        width: 40px;
        height: 40px;
        background: url("../../images/checkbox-checked-mob.svg");
      }
    }

    &__wrapper {
      .MuiSelect-icon {
        top: 12px;
      }

      .MuiSelect-select {
        line-height: 45px;
        height: 44px;
        font-size: 18px;
      }
    }
  }
}