.header {
  height: 666px;
  padding: 50px 20px 0;
  background-image: url("../../images/angel-left.png"), url("../../images/cloud-top-right.png"), url("../../images/cloud-bottom-right.png");
  background-repeat: no-repeat;
  background-position: bottom left, 95% top, right bottom;
  margin-bottom: -200px;
  position: relative;

  &__content {
    max-width: 460px;
    margin: auto;
  }

  &__description {
    font-size: 24px;
    line-height: 36px;
  }

  @media all and (max-width: 576px) {
    height: 620px;
    padding-top: 90px;
    background-image: url("../../images/cloud-top-left-mob.png"), url("../../images/cloud-top-right-mob.png"), url("../../images/header-bottom-mob.png"), linear-gradient(0deg, transparent 22%, rgba(235,245,247,1) 22%);
    background-position: top left, top right, center bottom;
    margin-bottom: -110px;
    background-size: auto, auto, contain;

    &__description {
      max-width: 320px;
      margin: 20px auto;
      font-size: 20px;
      line-height: 24px;
    }
  }
}