.input {
  border: 3px solid #fff;
  border-radius: 40px;
  padding: 13px 34px;
  color: #fff;
  font-size: 24px;
  background: transparent;
  transition: 0.4s;

  &:focus {
    outline: none;
    border-color: #09A0EE;
  }

  &__label {
    margin-left: 20px;
    color: #fff;
    font-weight: 500;
  }

  &::-webkit-input-placeholder {
    color: #fff;
  }

  @media all and (max-width: 576px) {
    font-size: 18px;
    padding: 12px 34px;

    &__label {
      font-size: 18px;
    }
  }
}

.label {
  display: flex;
  flex-direction: column;
}